import React, { useEffect, useState } from "react";
import { faArrowsRotate, faPen, faReceipt, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../configs/Utils";
import { useCookies } from "react-cookie";
import Enums from "../../configs/Enums";
import { Store } from "../../Models/Store";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';
import { useAuth } from "../../contexts/AuthContext";

interface StoreListItemProps {
  index: number;
  store: Store;
  storeList: Store[];
  crrStore: Store | null;
  setStoreList: React.Dispatch<React.SetStateAction<Store[]>>;
  setPopupShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCrrStore: React.Dispatch<React.SetStateAction<Store | null>>;
  flashSaleSubmit: boolean;
  setFlashSaleSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  flashsaleFormData: any;
  flashsaleAction: string;
}

const StoreListItem: React.FC<StoreListItemProps> = ({
  index,
  store,
  storeList,
  crrStore,
  setStoreList,
  setPopupShow,
  setCrrStore,
  flashSaleSubmit,
  setFlashSaleSubmit,
  flashsaleFormData,
  flashsaleAction
}) => {
  const navigate = useNavigate();
  const [cookie] = useCookies([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useAuth();

  const backgroundColor = Utils.stringToColor(store.flatform);
  const backgroundColorSeller = Utils.stringToColor(store.user?.fullName || 'N/A');

  useEffect(() => {
    const submitFlashSaleToTask = async () => {
      setIsLoading(true);
      const payload = {
        storeId: store.storeId,
        storeName: store.storeFriendlyName,
        ...flashsaleFormData
      };

      let postType = flashsaleAction === 'submit' ? 'POST' : 'DELETE';
      const createScheduleFlashsale = await Utils._apiRequest({
        path: Enums.endpoints.tiktok.CREATE_FLASH_SALE as string,
        type: postType,
        payload,
        cookie
      });

      if (createScheduleFlashsale.message === "Success") {
        store.flashsale = {};
        if (flashsaleAction === 'submit') {
          store.flashsale = createScheduleFlashsale.body?.data || {};
        }
      }

      setIsLoading(false);
    }
    if (flashSaleSubmit && crrStore?.storeId === store.storeId) {
      submitFlashSaleToTask();
      setFlashSaleSubmit(false);
    }
  }, [flashSaleSubmit, flashsaleAction]);

  const deleteItemHandle = async (id: string) => {
    const confirm = window.confirm(`Are you sure you want to delete [${store.storeFriendlyName}]? This action cannot be undone.`);
    if (confirm) {
      try {
        setDeleting(true);
        const data = await Utils._apiRequest({
          path: Enums.endpoints.store.DETAIL as string, 
          pathParams: { id },
          type: 'DELETE', 
          cookie
        });
        // remove current account from list
        const refreshData = storeList.filter((item) => {
          return item._id !== data._id;
        });
        setStoreList(refreshData);
        setDeleting(false);
      } catch(error) {
        setDeleting(false);
        window.alert((error as Error).message);
      }
    }
  }

  const refreshToken = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const newStore = await Utils._apiRequest({
      path: Enums.endpoints.tiktok.AUTH_TOKEN as string,
      type: 'GET',
      pathParams: { id: store._id },
      cookie
    });
    const expired = new Date(newStore.consumer.access_token_expire_in * 1000);
    alert(`The Token of "${store.storeFriendlyName}" was updated!\n\nThe new expired is: \n${expired}`);
    setIsLoading(false);
  }

  const createFlashSale = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCrrStore(store);
    setPopupShow(true);
  }

  return (
    <tr className={
      `table-row${deleting ? ' deleting': ''}${isLoading ? ' updating': ''}`
    }>
      <td>{(index + 1)}</td>
      <td>
        { store.flashsale?.discount ? (
          <Badge bg="danger-subtle" style={{color: '#bb2d3b'}} className="me-1"><FontAwesomeIcon icon={faReceipt} /> { store.flashsale?.discount }%</Badge>
        ) : (
          <></>
        )}
        <Link className="text-dark" to={ '/stores/' + store._id }>{ store.storeFriendlyName || store.storeName }</Link>
      </td>
      <td>{ Utils.buildBadgeLable(store.status) }</td>
      <td><Badge bg={store.orderCount? 'primary' : 'secondary'} >{ store.orderCount }</Badge></td>
      <td>
        <Badge bg="custom" style={{backgroundColor}} text="Light">{ store.flatform }</Badge>
        { Utils.buildBadgeLable(store.meta?.appVersion || 'V2', 'ms-1') }
      </td>
      <td><span className="text-overflow">{ store.domain || store.meta?.cipher }</span></td>
      { currentUser?.admin ? (
        <td><Badge bg="custom" style={{backgroundColor: backgroundColorSeller}} text="Light">{ store.user?.fullName || 'N/A' }</Badge></td>
      ) : <></> }
      <td className="text-inline text-end">
        <Tippy content={ store.flashsale?.discount ? "Update Flash Deals" : "Create Flash Deals" } theme="light" animation="scale">
          <Button className="ms-1" size="sm" variant="danger" onClick={createFlashSale}><FontAwesomeIcon icon={faReceipt} /></Button>
        </Tippy>
        
        <Tippy content="Refresh token" theme="light" animation="scale">
          <Button className="ms-1" size="sm" variant="warning" onClick={refreshToken}><FontAwesomeIcon icon={faArrowsRotate} /></Button>
        </Tippy>
        {/* <Tippy content="Edit account" theme="light" animation="scale">
          <Button className="ms-1" size="sm" variant="secondary" onClick={(e) => navigate('/stores/' + store._id)}><FontAwesomeIcon icon={faPen} /></Button>
        </Tippy> */}
        { currentUser?.admin ? (
          <Tippy content="Delete account" theme="light" animation="scale">
            <Button className="ms-1" size="sm" variant="danger" onClick={(e) => {deleteItemHandle(store._id)}}><FontAwesomeIcon icon={faTrashCan} /></Button>
          </Tippy>
        ) : <></> }
        
      </td>
    </tr>
  );
}

export default StoreListItem;